<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="flex justify-center mt-4 mb-2 items-center w-full">
        <router-link
          :to="`/available/${previousYearMonth}`"
          class="text-lg text-red font-semibold block text-blue-500"
        >
          &nbsp;<i class="fas fa-caret-left"></i>
        </router-link>
        <span class="mx-3">
          {{ `${currentMonthTranslation} ${selectedYear}` }}
        </span>
        <router-link
          :to="`/available/${nextYearMonth}`"
          class="text-lg text-red font-semibold block text-blue-500"
        >
          &nbsp;<i class="fas fa-caret-right"></i>
        </router-link>
      </div>
      <table class="mx-auto">
        <thead>
          <th
            class="text-sm py-3 px-8 border-t border-b border-gray-300"
            v-for="(dayName, index) in dayNames"
            :key="index"
          >
            {{ dayName }}
          </th>
        </thead>
        <tbody>
          <tr v-for="(week, index) in groupedDays" :key="index">
            <td
              class="px-8 border-t border-b border-gray-300 text-center"
              v-for="(day, dayIndex) in week"
              :key="dayIndex"
            >
              <span class="text-sm mb-2">{{ day }}</span>
              <div v-if="day" class="flex flex-col items-center h-14">
                <span v-if="isDayPlanned(day)" class="text-sm">
                  {{ $t("sheduled") }}
                </span>
                <span v-if="isDayCanceled(day)" class="text-sm">
                  {{ $t("schedule_cancelled") }}
                </span>
                <button
                  class="text-sm"
                  :class="[
                    hasAvailables(day) ? 'text-orange' : 'text-blue-500',
                    isDayPlanned(day) ? 'cursor-default' : 'underline',
                  ]"
                  @click="showSelectHourModal(day)"
                >
                  {{ selectedHourState(day) }}
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <t-modal
        v-model="selectHourModal"
        v-if="scenarioShifts"
        :header="$t('available_select_hour_modal_header', { date: form.date })"
      >
        <div v-if="scenarioShifts.length" class="flex flex-col mx-auto w-48">
          <div v-for="(timeSlot, index) in scenarioShifts" :key="index">
            <div class="flex items-center gap-4">
              <input
                type="radio"
                class="cursor-pointer"
                :id="timeSlot.id"
                name="hour"
                @click="selectedTimeSlot = timeSlot"
                :checked="
                  timeSlot.id === formTimeSlot
                    ? (selectedTimeSlot = timeSlot)
                    : null
                "
              />
              <label class="cursor-pointer" :for="timeSlot.id">
                {{ scenarioShiftsOptions(timeSlot.from, timeSlot.until) }}
              </label>
            </div>
          </div>
        </div>
        <div class="ml-3" v-else>
          <p>{{ $t("available_empty_timeslots") }}</p>
        </div>
        <template v-slot:footer>
          <div class="flex justify-end gap-4">
            <t-button
              type="button"
              variant="primary"
              class="text-sm"
              v-if="scenarioShifts.length"
              @click="onSubmit"
            >
              {{ $t("available_select_hour_submit_button") }}
            </t-button>
            <t-button
              type="button"
              class="text-sm"
              @click="selectHourModal = false"
            >
              {{ $t("close") }}
            </t-button>
          </div>
        </template>
      </t-modal>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import {
  getMonthTranslation,
  getTimeSlotOptions,
  getEndTimeDay,
  getScenarioShiftTimes,
} from "@/utils/misc";
import moment from "@/utils/moment-utc";
import * as _ from "lodash";
import { getters, actions } from "@/constants/state";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Layout },
  props: ["yearMonth"],
  data: () => ({
    loading: false,
    selectHourModal: false,
    selectedTimeSlot: {},
    formTimeSlot: 0,
    timeSlots: getTimeSlotOptions(),
    hourType: {
      empty: 0,
      allday: 1,
      evening: 3,
      custom: 4,
    },
    formHour: 0,
    form: {
      id: null,
      date: null,
      customFrom: "07:00",
      customEnd: "02:00",
    },
  }),
  async mounted() {
    this.loading = true;

    try {
      const yearMonth = moment(this.yearMonth, "YYYY-MM");
      await this.getUserAvailables({
        from: yearMonth.startOf("month").format("YYYY-MM-DD"),
        until: yearMonth.endOf("month").format("YYYY-MM-DD"),
        userId: this.userId,
      });
    } catch (error) {
      console.error("DEBUG: error", error);
    }

    this.loading = false;
  },
  methods: {
    ...mapActions({
      createUserAvailable: actions.AVAILABLES_CREATE_USER_AVAILABLE_ACTION,
      updateUserAvailable: actions.AVAILABLES_UPDATE_USER_AVAILABLE_ACTION,
      removeUserAvailable: actions.AVAILABLES_REMOVE_USER_AVAILABLE_ACTION,
      getUserAvailables: actions.AVAILABLES_FETCH_USER_AVAILABLES_ACTION,
      getDayScenarioShifts:
        actions.SCENARIO_SHIFTS_FETCH_DATE_SCENARIO_SHIFTS_ACTION,
    }),
    isDayPlanned(day) {
      return (
        _.get(this.availables, `${day}.is_planned`) &&
        !_.get(this.availables, `${day}.canceled`)
      );
    },
    isDayCanceled(day) {
      return _.get(this.availables, `${day}.canceled`);
    },
    selectedHourState(day) {
      const available = _.get(this.availables, day);

      if (!available) {
        return this.$i18n.t("available_selected_hour_empty");
      }

      let from = moment(available.from).format("HH:mm");
      let until = moment(available.until).format("HH:mm");

      if (from === "07:00" && until === "02:00") {
        return this.$i18n.t("available_selected_hour_allday");
      }

      if (from === "17:00" && until === "02:00") {
        return this.$i18n.t("available_selected_hour_evening");
      }

      from = from === "02:00" ? this.$i18n.t("close") : from;
      until = until === "07:00" ? this.$i18n.t("call") : until;
      until = until === "02:00" ? this.$i18n.t("close") : until;

      return `${from} - ${until}`;
    },
    async showSelectHourModal(day) {
      this.formTimeSlot = 0;
      const available = _.get(this.availables, day);

      if (_.get(available, "is_planned")) {
        return;
      }

      await this.getDayScenarioShifts({
        date: `${this.selectedYear}-${this.selectedMonth}-${day}`,
        userJobFilter: _.get(this.userJobFilter, "jobFilterId"),
      });

      this.form = {
        id: _.get(this.availables, `${day}.id`),
        date: `${this.selectedYear}-${this.selectedMonth}-${day}`,
      };

      if (available) {
        const availableFrom = moment(available.from).format("HH:mm");
        const availableUntil = moment(available.until).format("HH:mm");

        const shift = _.find(this.scenarioShifts, {
          from: availableFrom,
          until: availableUntil,
        });

        this.formTimeSlot = _.get(shift, "id");
      }

      this.selectHourModal = true;
    },
    async onSubmit() {
      this.loading = true;

      try {
        const payload = {
          from: `${this.form.date} ${this.selectedTimeSlot.from}:00`,
          until: getEndTimeDay(
            this.form.date,
            this.selectedTimeSlot.from,
            this.selectedTimeSlot.until
          ),
        };

        !this.form.id
          ? await this.createUserAvailable({
              ...payload,
              userId: this.userId,
            })
          : await this.updateUserAvailable({ ...payload, id: this.form.id });
        this.selectHourModal = false;
      } catch (error) {
        console.error("DEBUG: onSubmit error", error);
      }

      this.loading = false;
    },
    hasAvailables(day) {
      return _.has(this.availables, day);
    },
    scenarioShiftsOptions(from, until) {
      return getScenarioShiftTimes(from, until);
    },
  },
  computed: {
    ...mapGetters({
      userId: getters.AUTH_USER_ID_GETTER,
      userJobFilter: getters.SESSION_USER_JOB_FILTER_GETTER,
      scenarioShifts: getters.SCENARIO_SHIFTS_DATE_SCENARIO_SHIFTS_GETTER,
    }),
    selectedYear() {
      return moment(this.yearMonth, "YYYY-MM").format("YYYY");
    },
    selectedMonth() {
      return moment(this.yearMonth, "YYYY-MM").format("MM");
    },
    availables() {
      return _.reduce(
        this.$store.getters[getters.AVAILABLES_USER_AVAILABLES_GETTER],
        (calendar, available) => ({
          ...calendar,
          [moment(available.from).format("DD")]: available,
        }),
        null
      );
    },
    timeSlotOptions() {
      const timeSlots = getTimeSlotOptions(false);

      return {
        start: [
          {
            name: "",
            value: null,
          },
          ..._.map(timeSlots.start, (label, time) => ({
            name: label,
            value: time,
          })),
        ],
        end: [
          {
            name: "",
            value: null,
          },
          ..._.map(timeSlots.end, (label, time) => ({
            name: label,
            value: time,
          })),
        ],
      };
    },
    previousYearMonth() {
      return moment(this.yearMonth, "YYYY-MM")
        .subtract(1, "months")
        .format("YYYY-MM");
    },
    nextYearMonth() {
      return moment(this.yearMonth, "YYYY-MM")
        .add(1, "months")
        .format("YYYY-MM");
    },
    currentMonthTranslation() {
      return getMonthTranslation(
        moment(this.yearMonth, "YYYY-MM").format("MM")
      );
    },
    dayNames() {
      return [
        this.$i18n.t("monday_short"),
        this.$i18n.t("tuesday_short"),
        this.$i18n.t("wednesday_short"),
        this.$i18n.t("thursday_short"),
        this.$i18n.t("friday_short"),
        this.$i18n.t("saturday_short"),
        this.$i18n.t("sunday_short"),
      ];
    },
    groupedDays() {
      const yearMonth = moment(this.yearMonth, "YYYY-MM");
      const days = Array.from(Array(yearMonth.daysInMonth()), (_, i) =>
        `${i + 1}`.padStart(2, 0)
      );
      const startOfMonth = yearMonth.startOf("month").isoWeekday();

      for (let day = 1; day < startOfMonth; day++) {
        days.unshift(null);
      }

      return _.chunk(days, 7);
    },
  },
};
</script>

<style lang="scss" scoped>
.padding-none {
  padding: 0 !important;
}
.text-orange {
  color: orange;
}
</style>
